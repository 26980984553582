<template>
  <v-app>
    <core-snackbar />

    <router-view />
  </v-app>
</template>

<script>
export default {
  components: {
    CoreView: () => import('@/components/core/CoreView'),
    CoreSnackbar: () => import('@/components/core/CoreSnackbar'),
  },

  data: () => ({
    tab: 'reports',
  }),
}
</script>

<style>
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #b0b0b0 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #b0b0b0;
  border: 1px solid #ffffff;
}
</style>
